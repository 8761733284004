<template>
  <div class="flex">
    <NFormField
      label="Email"
      name="email"
      required
    >
      <NInput
        placeholder="phojrengel@gmail.com"
        leading="i-heroicons-envelope-20-solid"
      />
    </NFormField>
  </div>
</template>
