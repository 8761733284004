import revive_payload_client_akNF68BsvQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GvaiC8mHV4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_thbnchuHkV from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_XazDJZT96T from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.10_jiti@2.4._codoid6xoyndnnznwjbkpxepzi/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_nxAAb5ynDB from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Dkh0P1yggI from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Afl9tBnrbj from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nfvb9rZFI8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_uIe8xzxiFX from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ucaBwOA3Mk from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.10_jiti@2.4.2_sas_mjl3234aaszrreeacaj6jgkkz4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_IRjY5HqOd6 from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_nuxt@3.15.2_@parcel+watcher@2.5.0_tls5yx6uf5cfwciyslcgbjklcy/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import plugin_client_qJsI3aBQAJ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/docs/.nuxt/unocss.mjs";
import theme_client_8QYLtQWjlY from "/vercel/path0/packages/nuxt/src/runtime/plugins/theme.client.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/docs/plugins/analytics.client.ts";
export default [
  revive_payload_client_akNF68BsvQ,
  unhead_GvaiC8mHV4,
  router_thbnchuHkV,
  _0_siteConfig_XazDJZT96T,
  payload_client_nxAAb5ynDB,
  navigation_repaint_client_Dkh0P1yggI,
  check_outdated_build_client_Afl9tBnrbj,
  chunk_reload_client_nfvb9rZFI8,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uIe8xzxiFX,
  plugin_ucaBwOA3Mk,
  documentDriven_IRjY5HqOd6,
  plugin_client_qJsI3aBQAJ,
  unocss_MzCDxu9LMj,
  theme_client_8QYLtQWjlY,
  analytics_client_mtqVw3YRXk
]