<template>
  <div class="flex">
    <NFormGroup
      label="Email"
      name="email"
      message="We'll never share your email with anyone else."
    >
      <NInput
        placeholder="phojrengel@gmail.com"
        leading="i-heroicons-envelope-20-solid"
      />
    </NFormGroup>
  </div>
</template>
