import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_kvzlc2pu5fyz2jpupwtmcihbom/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.10_@unocss+preset-wind@65.4.3__ij7nlyvju4s367vys7sxcywjdi/node_modules/@una-ui/content/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}